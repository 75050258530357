import { transform } from "ol/proj";

import OlStyle from "ol/style/Style";
import OlStroke from "ol/style/Stroke";
import OlCircle from "ol/style/Circle";
import OlFill from "ol/style/Fill";
import OlIcon from "ol/style/Icon";
import OlText from "ol/style/Text";

import OlGeomMultiPoint from "ol/geom/MultiPoint";
import OlGeomPoint from "ol/geom/Point";
import OlGeomPolygon from "ol/geom/Polygon";
import OlGeomLineString from "ol/geom/LineString";
import {toStringHDMS} from 'ol/coordinate';
import {createStringXY} from 'ol/coordinate';

import { formatArea, formatLength } from "@/lib/olHelpers";

export {
  drawStyle,
  measurementsStyle,
  measuringStyle,
  recordStyle,
  workingRecordStyle,
  selectedRecordStyle,
  hoveringRecordStyle,
  elevationStyle,
  geoLocateStyle,
  routePointStyle
};


const recordStyle = () => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#f03b20",
      width: 3.25
    })
  });
  return style;
};

const workingRecordStyle = () => {
  return new OlStyle({
    image: new OlCircle({
      radius: 15,
      stroke: new OlStroke({
        color: "#FF00FF",
        width: 3.25
      })
    }),
    stroke: new OlStroke({
      color: "#FF00FF",
      width: 3.25
    }),
    fill: new OlFill({
      color: "rgba(255, 0, 255, 0.2)"
    })
  });
}

const selectedRecordStyle = (f: any) => {
  const id = f.getId();
  const iid = id ? (typeof id === "string" ? parseInt(id, 10) : id) : 0
  const label = id ? (iid % 1000000).toString() : '?';
  return new OlStyle({
    image: new OlCircle({
      radius: 15,
      stroke: new OlStroke({
        color: "#00FF00",
        width: 3.25
      })
    }),
    stroke: new OlStroke({
      color: "#00FF00",
      width: 3.25
    }),
    text: new OlText({
      text: label,
      overflow: true,
      fill: new OlFill({ color: "#000" }),
      stroke: new OlStroke({
        color: "#fff"
        , width: 8
      }),
    })
  });
};

const hoveringRecordStyle = (f: any) => {
  const id = f.getId();
  const iid = id ? (typeof id === "string" ? parseInt(id, 10) : id) : 0
  const label = id ? (iid % 1000000).toString() : '?';
  return new OlStyle({
    image: new OlCircle({
      radius: 15,
      stroke: new OlStroke({
        color: "#feb24c",
        width: 3.25
      })
    }),
    stroke: new OlStroke({
      color: "#feb24c",
      width: 3.25
    }),
    text: new OlText({
      text: label,
      overflow: true,
      fill: new OlFill({ color: "#000" }),
      stroke: new OlStroke({
        color: "#fff"
        , width: 8
      }),
    })
  });
};

const drawStyle = new OlStyle({
  image: new OlCircle({
    radius: 7,
    stroke: new OlStroke({
      color: "rgba(255, 255, 255, 0.79)"
    }),
    fill: new OlFill({
      color: "rgba(26, 114, 158, 0.79)"
    })
  }),
  fill: new OlFill({
    color: "rgba(255,255,255,0.4)"
  }),
  stroke: new OlStroke({
    color: "#3399CC",
    width: 3.25
  })
});

const measuringStyle = new OlStyle({
  fill: new OlFill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new OlStroke({
    color: 'rgba(0, 0, 0, 0.5)',
    lineDash: [10, 10],
    width: 4,
  }),
  image: new OlCircle({
    radius: 5,
    stroke: new OlStroke({
      color: 'rgba(0, 0, 0, 0.5)',
      width: 4
    }),
    fill: new OlFill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
  })
});

// @Piero
// not sure what f is at the moment (maybe layer)
const measurementsStyle = (f:any) => {
  const geom = f.getGeometry();

  var output;
  var tooltipCoord;

  if (geom instanceof OlGeomPolygon) {
    output = formatArea(geom);
    //tooltipCoord = geom.getInteriorPoint().getCoordinates();
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomLineString) {
    output = formatLength(geom);
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomPoint) {
    let htrs_array = transform(geom.getCoordinates(), "EPSG:3857", "EPSG:3765");
    output = "X: "+htrs_array[0].toFixed(0) + ", Y:" + htrs_array[1].toFixed(0);
    tooltipCoord = geom;
  }
  let style = [
    new OlStyle({
      image: new OlCircle({
        radius: 5,
        stroke: new OlStroke({
          color: 'rgba(0, 0, 0, 0.8)',
          width: 4
        }),
        fill: new OlFill({
          color: "rgba(255, 255, 255, 0.2)"
        })
      })
    }),
    new OlStyle({
      fill: new OlFill({
        color: "rgba(255, 255, 255, 0.5)"
      }),
      stroke: new OlStroke({
        color: 'rgba(0, 0, 0, 0.8)',
        lineDash: [10, 10],
        width: 4,
      })
    }),
    new OlStyle({
      text: new OlText({
        font: "12px sans serif",
        fill: new OlFill({ color: "#000" }),
        stroke: new OlStroke({
          color: "#fff"
          , width: 8
        }),
        text: output,
        textBaseline: "center",
        offsetY: -12
      })
    })
    // ,
    // new OlStyle({
    //   image: new OlCircle({
    //     radius: 3,
    //     stroke: new OlStroke({
    //       color: "#232f34"
    //     }),
    //     fill: new OlFill({
    //       color: "rgba(255, 255, 255, 0.2)"
    //     })
    //   }),
    //   geometry: function (feature) {
    //     // return the coordinates of the first ring of the polygon
    //     const geom = feature.getGeometry();
    //     const type = geom.getType();
    //     var coordinates = geom.getCoordinates
    //       ? type === "Polygon"
    //         ? geom.getCoordinates()[0]
    //         : geom.getCoordinates()
    //       : null;
    //     return coordinates ? new OlGeomMultiPoint(coordinates) : null;
    //   }
    // })
  ];
  return style;
};

const elevationStyle = (f: any) => {
  const alt = f.get("alt");
  const dh = f.get("dh");

  const dhValid = dh && !isNaN(parseFloat(dh))

  const lbl = "Alt: " + alt + " m" + (dhValid ?  ", Δh: " + dh + " m" : "");
  return (
    new OlStyle({
      image: new OlCircle({
        radius: 5,
        stroke: new OlStroke({
          color: "rgba(0, 0, 0, 0.8)",
          width: 4
        }),
        fill: new OlFill({
          color: "rgba(255, 255, 255, 0.2)"
        })
      })
    }),
    new OlStyle({
      text: new OlText({
        font: "12px sans serif",
        backgroundFill: new OlFill({ color: "#FFF" }),
        // backgroundStroke: new OlStroke({
        //   color: "#F00",
        //   width: 3
        // }),
        padding: [2,2,0,5],
        text: lbl,
        textBaseline: "center",
        offsetY: 16
      })
    })
  );
};

const geoLocateStyle = new OlStyle({
  image: new OlCircle({
    radius: 6,
    fill: new OlFill({
      color: '#3399CC',
    }),
    stroke: new OlStroke({
      color: '#fff',
      width: 2,
    })
  })
});

const routePointStyle = (f: any) => {
  const color = 'rgba(255,0,0,0.8)'
  return new OlStyle({
    image: new OlCircle({
      radius: 6,
      stroke: new OlStroke({
        color: color,
        width: 4
      }),
      fill: new OlFill({
        color: "rgba(255,255,255,1)"
      })
    })
  });
}
