import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";

const Vlasnik: IModel = {
  title: "Vlasnici",
  apiPath: "data/dkp/ko/{ko_mb}/kc/{kc_kb}",
  forms: {
    "default": {
      fields: ["id"]
    }
  },
  listViews: {
    "default": {
      fields: ["zku_broj", "naziv", "udio", "adresa_vl"]
    }
  },
  fields: [
    coreFields.id,
    { 
      title: "ZKU broj",
      source: "zku_broj",
      ttoken: "vlasnici.zku_broj",
      type: "text",
      readonly: true
    },
    { 
      title: "Broj katastarske općine",
      source: "ko_mb",
      ttoken: "vlasnici.ko_mb",
      type: "numeric",
      readonly: true
    },
    { 
      title: "Broj katastarske čestice",
      source: "kc_broj",
      ttoken: "vlasnici.kc_broj",
      type: "text",
      readonly: true
    },
    { 
      title: "Površina",
      source: "area",
      ttoken: "vlasnici.area",
      type: "text",
      readonly: true
    },
    { 
      title: "Uložak Id",
      source: "ulozak_id",
      ttoken: "vlasnici.ulozak_id",
      type: "numeric",
      readonly: true
    },
    { 
      title: "Udio",
      source: "udio",
      ttoken: "vlasnici.udio",
      type: "text",
      readonly: true
    },
    { 
      title: "Naziv",
      source: "naziv",
      ttoken: "vlasnici.naziv",
      type: "text",
      readonly: true
    },
    { 
      title: "Adresa vlasnika",
      source: "adresa_vl",
      ttoken: "vlasnici.adresa_vl",
      type: "text",
      readonly: true
    },
  ]
}

export default Vlasnik;
