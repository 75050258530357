import React, { Fragment, useState, useEffect, useContext, useCallback, useMemo } from "react";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination
} from "react-table";
import { Column, Row, SortingRule } from "react-table";

//Material UI Core Components
import { useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MaterialUITable from "@mui/material/Table";
import Switch from "@mui/material/Switch";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

//Custom Components
import FormButton from "@/ui/Form/FormButton";
import IncrementControl from "@/controls/IncrementControl";
import TableCheckbox from "@/ui/Table/TableCheckbox";
import TableRadio from "@/ui/Table/TableRadio";
import TableHeading from "@/ui/Table/TableHeading";
import TableSearchBar from "@/ui/Table/TableSearchBar";
import TableHeaderButton from "./HeaderButtons/TableHeaderButton";
import TableHeaderButtonRefresh from "@/ui/Table/HeaderButtons/TableHeaderButtonRefresh";
import TableHeaderButtonColumnPicker from "@/ui/Table/HeaderButtons/TableHeaderButtonColumnPicker";
import TableHeaderButtonExport from "@/ui/Table/HeaderButtons/TableHeaderButtonExport";
import TableHeaderButtonFilter from "@/ui/Table/HeaderButtons/TableHeaderButtonFilter";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import { localStorageAPI } from "@/lib/localStorageAPI";
import ThemeContext from "@/components/ThemeContext/ThemeContext";
import UserContext from "@/components/UserContext/UserContext";

import {
  ITableProps,
  IFilterValue,
  RowClickFn,
  GlobalFilterFn,
  RowField
} from "@/@types/ui/Table";

interface IFilterState {
  [key: string]: IFilterValue;
}

interface IColumn {
  source: string;
  hidden: boolean;
}

interface ISort {
  id: string;
  desc?: boolean;
}

//test build

function Table(props: ITableProps) {
  // Use the state and functions returned from useTable to build your UI
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  const [rowId, setRowId] = useState(-1);
  const [recordsNumber, setRecordsNumber] = useState(0);
  const [firstEntry, setFirstEntry] = useState(false);
  const [pageCurrent, setPageCurrent] = useState(0);
  const [sorts, setSorts] = useState([]);

  const { t } = useTranslation();
  const theme = useTheme();

  const { disableControls, paginationPosition } = props;
  const [tableControls, setTableControls] = useState<boolean>(!Boolean(disableControls));
  const [inc, setInc] = useState<number>(60);
  const [canFilter, setCanFilter] = useState(false);

  const [oldGlobalFilter, setOldGlobalFilter] = useState<string|undefined>(undefined);

  const {
    columns,
    records,
    hiddenColumnNames,
    // onToggleColumnVisibility,
    onRefresh,
    children,
    showControlSwitch,
    allowAdd,
    allowColumnPicker,
    allowEdit,
    allowExport,
    allowFilter,
    allowInc,
    allowSearchBar,
    allowSelection,
    addLabel,
    handleAdd,
    onEdit,
    // topAlignedSearch,
    dc,
    displayHeader,
    tableName,
    // viewName,
    title,
    subTitle,
    // pagination,
    // headerContent,
    identifier,
    onRowClick,
    onDoubleClick,
    initialPageSize = 10,
    rowSelectedCustom,
    RowTooltip = ({ children }) => children,
    trackTableState,
    unselectRow = true,
    getRowStyle,
    globalFilterIntersection = true,
    onGlobalFilterChange,
    customRecordCount,
    gotoFirstPageOnSelect,
  } = props;

  useEffect(() => {
    if (rowSelectedCustom) {
      setRowId(rowSelectedCustom);
      if (rowSelectedCustom === -1) {
        console.log(oldGlobalFilter)
        setTimeout(() => {
          setGlobalFilter(oldGlobalFilter)
        }, 0)
      }
    }
  }, [rowSelectedCustom])

  // useEffect(() => {
  //   if (rowSelectedCustom === -1) {
  //     console.log(oldGlobalFilter)
  //     setTimeout(() => {
  //       setGlobalFilter(oldGlobalFilter)
  //     }, 0)
  //   }
  // }, [records])

  // const [filters, setFiltersToggle] = useState<IFilterState | null>(userContext !== null ?
  //   userContext.getRecent("toggle_filter", identifier, {}) as IFilterState
  // : null);
  const [filters, setFiltersToggle] = useState<IFilterState | null>(null);

  // const selectOnlyOne = function(evt, value, onChange, toggleAllRowsSelected) {
  //   toggleAllRowsSelected(false);
  //   onChange(evt, value);
  // };

  const handleRowClick: RowClickFn = function (evt, toggleRowSelected, toggleAllRowsSelected, recordId) {
    switch (allowSelection) {
      case "none":
        return;
      case "one":
        if (recordId !== rowId) {
          setRowId(recordId);
          toggleAllRowsSelected(false);
          toggleRowSelected();
        } else if (unselectRow) {
          setTimeout(() => {
            setGlobalFilter(oldGlobalFilter);
          }, 0)
          setRowId(-1);
          toggleAllRowsSelected(false);
          toggleRowSelected();
        }
        if (onRowClick) {
          onRowClick(recordId);
        }
        return;
      case "many":
        toggleRowSelected();
        break;
    }
  };

  const handleDoubleClick: RowClickFn = function (evt, toggleRowSelected, toggleAllRowsSelected, recordId) {
    if (recordId !== rowId) {
      toggleAllRowsSelected(false);
      if (onDoubleClick) {
        onDoubleClick(recordId);
      }
    }
  };

  const initialPageSizeKey = "initialPageSize";
  const src = dc.getSource();

  // const initialPageSize = localStorageAPI.getValue(initialPageSizeKey, identifier, 10);

  // If a new type has a return value that is a div, please define value={value} in its props in order for the column to be searchable
  // If that isn't enough for the new type, define a new case for the new type created here in globalFilter
  const globalFilterxx: GlobalFilterFn = (rows, ids, filterValue) => {
    if (rows && ids && filterValue) {
      return rows.filter((row) => {
        return ids.some((id) => {
          let rowValue = "";
          const field = dc.getField(id);
          if (field && field.type) {
            const type = field.type;

            if (type === "button" || !row.values[id]) {
              return false;
            }

            switch (type) {
              case "date":
              case "datetime":
                rowValue = row.values[id];
                return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
              case "currency":
                rowValue = row.values[id];
                break;
              case "active":
                rowValue = row.values[id];
                break;
              case "text":
                rowValue = row.values[id];
                break;
              default:
                // rowValue = row.values[id] instanceof Object ? row.values[id].props ? row.values[id].props.value : row.values[id](0) : row.values[id];
                rowValue = row.values[id];
                break;
            }
          }

          return String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
        });
      });
    }
    return [];
  };

  //@ts-ignore
  const customGlobalFilter: GlobalFilterFn = (rows, ids, filterValue) => {
    if (filterValue) {
      const values = filterValue.split(' ').map((s: string) => s.trim()).filter((s: string) => s.length > 0) as string[];

      // filter rows so that every value from filterValue split by <space> is present in at least
      // one of the columns, check only string and number values
      return rows.filter((r: RowField) => (
        values.every(val => (
          ids.some(f => (
            r.original[f] &&
              (
                typeof r.original[f] === 'string' && r.original[f].toLowerCase().indexOf(val.toLowerCase()) !== -1 ||
                typeof r.original[f] === 'number' && r.original[f].toString().toLowerCase().indexOf(val.toLowerCase()) !== -1
              )
        ))))));
    }

    return rows || [];
  }

  const initialSorts = useMemo(() => {
    return trackTableState && userContext !== null ?
      userContext.getRecent("sorts", identifier, []) as Array<SortingRule<ISort>>
      : [];
  }, [src, trackTableState]);

  const initialFilters: Array<IFilterValue> = useMemo(() => {
    const filtersArray = [];
    const filters_ = (trackTableState && userContext !== null ?
      userContext.getRecent("filters", identifier, {}) : {}) as { [k: string]: string };
    if (filters !== null) {
      return Object.keys(filters_).map(id => ({ id, value: filters_[id] }));
    }
    return [];
  }, [src, trackTableState]);

  const userDefinedColumnVisibility = userContext !== null ?
    userContext.getRecent("columns", identifier, []) as Array<IColumn>
    : [];

  const userHiddenFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === true).map(x => x.source);
  const userVisibleFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === false).map(x => x.source);
  const uniqueHiddenFieldNames = Array.from(new Set(userHiddenFieldNames.concat(hiddenColumnNames)));

  // const finalHiddenColumnNames = uniqueHiddenFieldNames.filter(x => userVisibleFieldNames.indexOf(x) === -1);
  const finalHiddenColumnNames = hiddenColumnNames;

  const {
    getTableProps,
    toggleAllRowsSelected,
    headerGroups,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    setAllFilters,
    setFilter,
    setHiddenColumns,
    state: { selectedRowIds, globalFilter, sortBy, pageIndex, pageSize, hiddenColumns }
  } = useTable(
    {
      columns: columns,
      autoResetFilters: false,
      data: records,
      initialState: {
        pageSize: initialPageSize || 10,
        hiddenColumns: finalHiddenColumnNames,
        sortBy: initialSorts,
        filters: initialFilters
      },
      globalFilter: globalFilterIntersection ? customGlobalFilter : 'text'
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (instance) => {
      instance.allColumns.push((columns) => {
        switch (allowSelection) {
          case "none":
            return [...columns];
          case "one":
          case "many":
            return [
              ...columns,
              // {
              //   id: "selection",
              //   Header: ({ getToggleAllRowsSelectedProps }) => (
              //     <div>
              //       {allowSelection === "many" ? (
              //         <TableCheckbox {...getToggleAllRowsSelectedProps()} title={"Toggle"}/>
              //       ) : null}
              //     </div>
              //   ),
              //   Filter: () => (null),
              //   Cell: ({ row, toggleAllRowsSelected }) => {
              //     const { onChange, checked, indeterminate, style } = row.getToggleRowSelectedProps();

              //     switch (allowSelection) {
              //       case "one":
              //         return (
              //           <div>
              //             <TableRadio
              //               // onClick={(evt, val) => { selectOnlyOne(evt, val, onChange, toggleAllRowsSelected) }}
              //               checked={checked}
              //               style={style}
              //               title={"Toggle"}
              //             />
              //           </div>
              //         );
              //       case "many":
              //       default:
              //         return (
              //           <div>
              //             <TableCheckbox {...row.getToggleRowSelectedProps()} title={"Toggle"} />
              //           </div>
              //         );
              //     }
              //   }
              // }
              allowEdit ?
                ({
                  id: "edit",
                  Header: () => t("buttons.edit"),
                  Filter: () => (null),
                  Cell: ({ row }) => (
                    <div style={{ display: "inline" }}>
                      <IconButton size="small" onClick={(evt) => onEdit((row as RowField).original.id)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </div>
                  )
                }) as Column
                : { id: "noRender" }
            ].filter(a => a.id !== "noRender");
          default:
            return [...columns];
        }
      });
    }
  );

  useEffect(() => {
    if (onGlobalFilterChange) {
      onGlobalFilterChange(globalFilter);
    }
    if (rowId !== -1 && globalFilter === undefined) {
      let index: number = 0;
      let rec: RowField | undefined;
      for (let i = 0; i < rows.length; ++i) {
        if ((rows[i] as RowField).original['id'] === rowId) {
          rec = rows[i];
          index = i;
          break;
        }
      }
      const page = Math.floor(index / pageSize);
      setPageCurrent(page);
      // console.log(index, rec)
    }
    // console.log(globalFilter, rowId, rows)
  }, [globalFilter])

  useEffect(() => {
    if (gotoFirstPageOnSelect === undefined || gotoFirstPageOnSelect === true) {
      setPageCurrent(0);
      return;
    }
    if (rowId !== -1) {
      let index: number = 0;
      let rec: RowField | undefined;
      for (let i = 0; i < records.length; ++i) {
        // if ((rows[i] as RowField).original['id'] === rowId) {
        if (records[i].id === rowId) {
          rec = rows[i];
          index = i;
          break;
        }
      }
      const page = Math.floor(index / pageSize);
      if (page !== pageCurrent) {
        setPageCurrent(page);
      }
    }
  }, [rowId])

  const arrSelectedRowsIds = Object.keys(selectedRowIds).filter((x) => selectedRowIds[x] === true);

  // Try me and remove this boy one more time!
  console.log;

  if (!firstEntry && rows.length !== recordsNumber) {
    setRecordsNumber(rows.length);
    setFirstEntry(true);
  }

  if (pageIndex !== pageCurrent) {
    if (rows.length !== recordsNumber) {
      gotoPage(0);
      setPageCurrent(0);
      setRecordsNumber(rows.length);
    } else {
      gotoPage(pageCurrent);
      setRecordsNumber(rows.length);
    }
  }

  if (Math.floor(rows.length / initialPageSize) < pageCurrent || Math.floor(rows.length / initialPageSize) < pageIndex) {
    return null;
  }

  const handleToggleFilter = function (evt: MouseEvent<HTMLButtonElement>) {
    if (filters) {
      setAllFilters([]);
      if (userContext !== null) {
        userContext.setRecentFilters(identifier, "", "", Boolean(filters));
      }
    }

    if (userContext !== null) {
      userContext.setRecent("toggle_filter", identifier, {});
    }
    setFiltersToggle(filters === null ? {} : null);
    setCanFilter(!canFilter)
  };

  const setSort = (
    evt: MouseEvent<HTMLElement>,
    // onClick: (evt: MouseEvent<HTMLElement>) => void,
    key: string,
    isDesc: boolean | undefined
  ) => {
    if (evt.shiftKey) {
      let _sorts = userContext !== null ? userContext.getRecent("sorts", identifier, []) as Array<ISort> : [];
      const _item = _sorts.find(x => x.id === key);

      if (isDesc === undefined) {
        if (!_item) {
          _sorts.push({ id: key, desc: false });
        }
      } else if (!isDesc) {
        if (_item) {
          _item.desc = true;
        }
      } else if (isDesc) {
        if (_item) {
          const newSorts = _sorts.filter(x => x.id !== key);
          if (userContext !== null) {
            userContext.setRecent("sorts", identifier, newSorts);
          }
        }
        // onClick(evt);
        return;
      }
      if (userContext !== null) {
        userContext.setRecent("sorts", identifier, _sorts);
      }
    } else {
      const singleSorts = isDesc === undefined ?
        [{ id: null, desc: null }]
        : [{ id: key, desc: isDesc }];
      if (userContext !== null) {
        userContext.setRecent("sorts", identifier, singleSorts);
      }
    }
    // onClick(evt);
  };

  // @Piero delete this
  const paginate = function () {
    if (rows.length >= initialPageSize /*&& pagination*/) {
      return page;
    } else {
      return rows;
    }
  };

  const textFilter = (isActive: boolean, lightTheme: boolean) => {
    if (!isActive) {
      if (lightTheme) {
        return "rgba(0,0,0,0.4)";
      } else {
        return "rgba(250,250,250,0.5)";
      }
    } else {
      return "";
    }
  };

  const handleToggleColumnVisibility = useCallback((source, isVisible) => {
    let _columns = userContext !== null ? userContext.getRecent("columns", identifier, []) as Array<IColumn> : [];
    const _item = _columns.find(x => x.source === source);

    let newHidden;
    if (isVisible) {
      newHidden = hiddenColumns ? hiddenColumns.filter(x => x !== source) : [];
      if (_item) {
        _item.hidden = false;
      } else {
        _columns.push({ source: source, hidden: false });
      }
    } else {
      newHidden = hiddenColumns ? hiddenColumns.concat([source]) : [source];
      if (!_item) {
        _columns.push({ source: source, hidden: true });
      } else {
        _item.hidden = true;
      }
    }

    const cleanArray = Array.from(new Set(newHidden));
    if (userContext !== null) {
      userContext.setRecent("columns", identifier, _columns);
    }
    setHiddenColumns(cleanArray);
    // NOTE: Does not work

    // setUserFieldVisibility(prevState => {
    //   return {
    //     ...prevState,
    //     [source]: isVisible
    //   }
    // })
  }, [hiddenColumns]);

  const toggleTableControls = () => {
    setTableControls(!tableControls);
  }

  const trimString: (v: string) => string = (v) => {
    if (v.length > inc) {
      return v.slice(0, Math.max(1, inc)).trim() + "...";
    }
    return v;
  }

  return (
    <Fragment>
      <Card elevation={0} style={{ height: "100%" }}>
        <CardContent sx={{ p: 0 }} style={{ display: "flex", flexDirection: "column", height: "100%", minHeight: "100%" }}>
          {
            displayHeader ?
              <Toolbar variant="dense">
                <Typography variant="h5" component="h3">
                  {title}
                </Typography>
                {
                  subTitle ?
                    <Typography variant="caption" component="h6">
                      &nbsp;{subTitle}
                    </Typography>
                    : null
                }
                {allowSearchBar ?
                  <TableSearchBar
                    count={customRecordCount}
                    // value={globalFilter}
                    // onChange={setGlobalFilter}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    // setGlobalFilter={setGlobalFilter}
                    setGlobalFilter={(value: string | undefined) => {
                      setPageCurrent(0);
                      setGlobalFilter(value);
                      setOldGlobalFilter(value);
                      if (value === undefined && onGlobalFilterChange) {
                        onGlobalFilterChange(value)
                      }
                    }}
                  />
                  : null
                }
                <ToolbarFillContent />
                {
                  page.length > 0 && records.length > pageSize && initialPageSize < 10000 && paginationPosition === "top" ?
                    <TablePagination
                      component="div"
                      onPageChange={(evt, page) => setPageCurrent(page)}
                      page={pageIndex}
                      rowsPerPage={pageSize}
                      count={rows.length}
                      // onRowsPerPageChange={(evt) => setPageSize(parseInt(evt.target.value, 10))}
                      onRowsPerPageChange={(evt) => {
                        const rowsPerPage = parseInt(evt.target.value, 10)
                        setPageCurrent(0);
                        setPageSize(rowsPerPage);
                      }}
                      labelRowsPerPage={t("table.rows")}
                      size="small"
                    />
                    : null
                }
                {showControlSwitch ?
                  <Switch
                    checked={tableControls}
                    onChange={toggleTableControls}
                    size="small"
                  /> : null}
                {allowAdd && handleAdd !== undefined ? (
                  <TableHeaderButton onClick={() => handleAdd()} startIcon={<AddIcon />} variant="contained">
                    {addLabel}
                  </TableHeaderButton>
                ) : null}
              </Toolbar>
              : null
          }
          <Box m={0} style={{ display: "flex", flexDirection: "column", flexGrow: "1", overflowY: "auto" }}>
            {
              tableControls ?
                <Toolbar variant="dense">
                  <ToolbarFillContent />
                  {allowInc ?
                    <IncrementControl value={inc} onChange={setInc} />
                    : null}
                  {allowColumnPicker ?
                    <TableHeaderButtonColumnPicker columns={columns} hiddenColumnNames={hiddenColumns} onChange={handleToggleColumnVisibility} />
                    : null}
                  {allowFilter ?
                    <TableHeaderButtonFilter filters={canFilter} onClick={handleToggleFilter} />
                    : null}
                  {allowExport ?
                    <TableHeaderButtonExport dc={dc} rows={rows} records={records} tableName={tableName} />
                    : null}
                </Toolbar>
                : null
            }
            <TableContainer sx={{
              '.MuiTableContainer-root': {
                [theme.breakpoints.down("md")]: {
                  overflowX: "scroll"
                }
              }
            }}>
              <MaterialUITable {...getTableProps()} stickyHeader={true} size="small">
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, c) => {
                        const { /*onClick,*/ ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());
                        const id = column["id"];

                        return (
                          //@ts-ignore
                          <TableCell style={column.style} size="small">

                            {column.canSort ? (
                              <div {...headerProps}>
                              <TableSortLabel
                                active={column.isSorted}
                                direction={column.isSortedDesc ? "desc" : "asc"}
                                onClick={(evt) => setSort(evt, /*onClick,*/ id, column.isSortedDesc)}
                              >
                                <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 800 }}>{column.render("Header")}</Typography>
                              </TableSortLabel>
                              </div>
                            ) : (
                              <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 800 }}>{column.render("Header")}</Typography>
                            )}
                            {
                              canFilter && column.canFilter ? (
                                <div className="table-filter" key={c + "-filter"}>
                                  {column.render("Filter")}
                                </div>
                              ) : null}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {paginate().map((row: Row, i) => {
                    prepareRow(row);
                    const { original } = row as RowField;
                    // this comment fixes wrong selection on single select tables but what with multi select? -Piero
                    const isItemSelected = /*selectedRowIds[pageIndex * pageSize + i] === true ||*/ rowId === original['id'];
                    // this piece of work ensures that children of table get selection property. Is it useful here? Nobody knows
                    if (isItemSelected && arrSelectedRowsIds.length === 0) {
                      arrSelectedRowsIds.push(row.id);
                      row.toggleRowSelected();
                    }
                    // console.log(isItemSelected, getRowStyle)
                    const rowStyle = !isItemSelected && getRowStyle ? getRowStyle(original["id"]) : {};
                    // if this row has active as a property it will be a function which will return its boolean value before translation if true is passed to it
                    const isActive = !original.hasOwnProperty("active") || original['active'];
                    return (
                      <RowTooltip key={i} original={original}>
                        <TableRow
                          hover={true}
                          selected={isItemSelected}
                          role="check"
                          aria-checked={isItemSelected}
                          {...row.getRowProps()}
                          onDoubleClick={(evt) => handleDoubleClick(evt, row.toggleRowSelected, toggleAllRowsSelected, original['id'])}
                          onClick={(evt) => handleRowClick(evt, row.toggleRowSelected, toggleAllRowsSelected, original['id'])}
                          style={rowStyle}
                        >
                          {row.cells.map((cell) => {
                            return <TableCell {...cell.getCellProps({
                              style: {
                                overflowWrap: "break-word",
                                cursor: "pointer",
                                color: textFilter(isActive, themeContext !== null && themeContext.theme === "light" ? true : false)
                              }
                            })} size="small">{cell.render("Cell", { trimString })}</TableCell>;
                          })}
                        </TableRow>
                      </RowTooltip>
                    );
                  })}
                </TableBody>
                {paginationPosition === "bottom" ?
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={rows.length}
                        onPageChange={(evt, page) => setPageCurrent(page)}
                        page={pageIndex}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={(evt) => setPageSize(parseInt(evt.target.value, 10))}
                        labelRowsPerPage={t("table.rows")}
                      />
                    </TableRow>
                  </TableFooter>
                  : null}
              </MaterialUITable>
            </TableContainer>
            {
              children && children.length &&
              <Toolbar variant="dense" disableGutters={true}>
                <div style={{ flexGrow: 1 }} />
                {React.Children.map(children, (child) => {
                  return React.cloneElement(child, { selection: arrSelectedRowsIds });
                })}
              </Toolbar>
            }
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  );
}

Table.propTypes = {
  records: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  allowSelection: PropTypes.oneOf(["none", "one", "many"])
};

export default Table;
