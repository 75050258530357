import * as React from "react";
import { useContext, Fragment, FunctionComponent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import gsService from "@/services/gsService"

//Types
import { ILegendPane } from "@/@types/components/MapSidebarPanes";

const LegendPane: FunctionComponent<ILegendPane> = (props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme() as any;

  const { layersCollection } = props;

  const bgColor = theme.palette.background.default.substring(1);
  const fontColor = theme.palette.mode == "light" ? "000000" : "FFFFFF";

  return (
    <Fragment>
      { layersCollection ?
        layersCollection.getArray().map((layer: any, i: number) => 
          layer.getProperties().legend ?
            <Box key={i}>
              <Typography variant="h6">
                {t(layer.getProperties().legend_ttoken)}
              </Typography>
              <img 
                src={gsService.getLegend(layer.getProperties(), i18n.language, bgColor, fontColor)}
              />
            </Box>
          : null 
      ) : null }
    </Fragment>
  );
};

export default LegendPane;

