import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";

const Stabla: IModel = {
  title: "Stabla",
  source: "stabla",
  apiPath: "stabla/",
  forms : {
    "default": {
      fields: ["id", "a"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "a"],
      hidden: ["id"]
    },
    "tooltip": {
      fields: ["id", "a"],
    },
    "attribute_table": {
      fields: ["id", "a"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Visina",
      source: "a",
      ttoken: "stabla.visina",
      type: "numeric",
      readonly: true
    }
  ]
}

export default Stabla
