import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";

//MUI
import Typography from "@mui/material/Typography";

//MUI LAB
import { TreeItemContentProps, useTreeItem } from "@mui/lab/TreeItem";

// Components
import MapContext from "@/components/Map/MapContext";
import OpacitySlider from '@/ui/Slider/OpacitySlider';
import { findLayer } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const LayerTreeItemCustomContent = React.forwardRef(function LayerTreeItemCustomContent(
  props: TreeItemContentProps,
  ref
) {
  const mapContext = useContext(MapContext) as MapContextType;
  const [ opacityValue, setOpacityValue ] = useState(100)
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;
  // @ts-ignore
  const { checked } = props;

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const opacitySliderItems = ['app-dof', 'app-objekti', 'app-dem', 'app-dsm', 'app-nagib', 'app-op']
  const hasOpacitySlider = nodeId && opacitySliderItems.includes(nodeId)

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  function handleChangeOpacity(event: Event, value: number | Array<number>, activeThumb: number) {
    if (typeof value === 'number') {
      setOpacityValue(value)
    }
  }

  useEffect(() => {
    // Gets current opacity from mapLayer and sets the opacityValue state. Without this opacity will be set to 100% on every rerender.
    if (mapContext && mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.nodeId);
      const lastOpacity = mapLayer?.getOpacity()
      if (typeof lastOpacity === 'number') {
        setOpacityValue(Math.round(lastOpacity * 100))
      }
    }
  }, [])

  useEffect(() => {
    if (mapContext && mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.nodeId);
      mapLayer?.setOpacity(opacityValue / 100);
    }
  }, [opacityValue])

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
      { hasOpacitySlider ? <OpacitySlider
        opacity={opacityValue}
        checked={checked} // Currently always active
        handleChange={handleChangeOpacity}
      />: null }
    </div>
  );
});

export default LayerTreeItemCustomContent;
