import React, { Component, Fragment, useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from "moment";
//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

// d.ts
import { DrawingType, DrawingInteractionType, CoordinateSystemType, BoolSettings, ViewOptionsType } from "@/@types/common/index";
import {
  CardOverlayType,
  CardPositon,
  ICardOverlay,
  ICardOverlayData,
  IMapProps,
  IMapState
} from "@/@types/views/GIS/map";
import { GFIResponseType } from "@/@types/services/gsService";

// OpenLayers
import Geometry from "ol/geom/Geometry";
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import Projection from "ol/proj/Projection";
import OlLayerGroup from "ol/layer/Group";
import OlSourceVector from "ol/source/Vector";
import Map from "@/components/Map/Map";
import MapEvent from "ol/MapEvent";
import { DrawEvent } from "ol/interaction/Draw";
import BaseEvent from "ol/events/Event";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
import OlBaseLayer from "ol/layer/Base";
import OlFeature from "ol/Feature";
import OlCollection from "ol/Collection";
import OlPolygon from "ol/geom/Polygon";
import OlPoint from "ol/geom/Point";
import OlLineString from "ol/geom/LineString";

// Custom
import { Permissions as P } from "@/lib/permissions";
import {
  Controls,
  CoordZoomStatusControl,
  DefaultControls,
  FullScreenControl,
  MeasureControls,
  RotateControl,
  ScaleLineControl,
  ScaleRatioControl,
  StatusControl,
  ZoomControl,
  ZoomToExtentControl,
  GeoLocateControl,
  ZoomSliderControl,
  CenterMarkerControl,
  AttributeTableControl,
  LayerTree,
  ViewHistoryControl,
  PrintControl
} from "@/components/Map/Controls";

import AttributeTable from "@/components/Map/Controls/AttributeTable/AttributeTable";

import {
  SidebarContent,
  SidebarControl,
  SidebarHeading,
  SidebarPane,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem
} from "@/components/Map/Controls/Sidebar";

import { InfoPane, LayersPane, MeasuresPane, DataPane } from "@/components/MapSidebarPanes";
import { Layers, TileLayer, VectorLayer, GroupLayer } from "@/components/Map/Layers";
import { Overlays, PopupOverlay, MeasureTooltipOverlay } from "@/components/Map/Overlays";
import { Interactions, DefaultInteractions, DrawInteraction } from "@/components/Map/Interactions";
import {
  measurementsStyle,
  measuringStyle,
  selectedRecordStyle,
  hoveringRecordStyle,
  elevationStyle
} from "@/components/Map/mapStyles";
import UserConsumer from "@/components/UserContext/UserConsumer";
import UserContext from "@/components/UserContext/UserContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import { flattenLayers } from "@/lib/olHelpers";

import GeoAPILayers from "@/components/Map/Layers/GEO/GeoAPILayers";
import GeoBaseLayerSwitcher from "@/components/Map/Controls/GEO/GeoBaseLayerSwticher";
import GSInfoCard from "./GSInfoCard";

import model from "@/models/objekti";
import dataController from "@/lib/dataController";
import exportCSV from "@/lib/exportCSV"

//Services
import mapService from "@/services/mapService";
import gsService from "@/services/gsService";
import { authService } from '@/services/authService';
import { DCRecord } from "@/@types/lib/dataController";
import { GFIFeatureType } from "@/services/gsService";
import { helpers } from "@/lib/helpers";
import { getCalendarPickerSkeletonUtilityClass } from "@mui/lab";
import LegendPane from "@/components/MapSidebarPanes/LegendPane";

function MainMap(props: IMapProps) {
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);

  const [mapInitialized, setMapInitialized] = useState(false);

  const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
    center: userContext?.mapSettings ? userContext.mapSettings.initial_view_center : [1731757, 5581737],
    zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 8,
    extent: userContext?.mapSettings ? userContext.mapSettings.max_extent : undefined,
    //projection: wgs84PM,
    minZoom: 8,
    maxZoom: 21,
    constrainResolution: true,
    showFullExtent: true
  });

  const [ elevationPointsSource, setElevationPointsSource] = useState<OlSourceVector<OlPoint>>(new OlSourceVector({}));
  const elevationPointsFeatures = useMemo(()=> elevationPointsSource.getFeatures(), [elevationPointsSource])

  const [chosenCoordinateSystem, setChosenCoordinateSystem] = useState<CoordinateSystemType>("HTRS96/TM");
  const [layersCollection, setLayersCollection] = useState<OlCollection<OlBaseLayer> | undefined>(undefined);
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
  const [drawType, setDrawType] = useState<DrawingType | undefined>(undefined);
  const [drawInteractionType, setDrawInteractionType] = useState<DrawingInteractionType | undefined>(undefined);
  const [feature, setFeature] = useState<OlFeature<Geometry> | undefined>(undefined);
  const [measuringFeature, setMeasuringFeature] = useState<OlFeature<Geometry> | undefined>(undefined);
  const [cardOverlay, setCardOverlay] = useState<ICardOverlay | undefined>(undefined);
  const [defaultExtent, setDefaultExtent] = useState(userContext?.mapSettings?.default_extent);

  const [workingRecords, setWorkingRecords] = useState<DCRecord[]>([]);
  const [workingSource, setWorkingSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const [selectedRecords, setSelectedRecords] = useState<DCRecord[]>([]);
  const [selectedSource, setSelectedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const [drawingSource, setDrawingSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [measurementsSource, setMeasurementsSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const zoomToDefaultExtentElementRef = useRef<HTMLLIElement>(null);
  const zoomToSelectedExtentElementRef = useRef<HTMLLIElement>(null);
  const fullScreenElementRef = useRef<HTMLLIElement>(null);
  const geoLocateElementRef = useRef<HTMLLIElement>(null);
  const centerMarkerElementRef = useRef<HTMLLIElement>(null);
  const printElementRef = useRef<HTMLLIElement>(null);

  const [recordsAdrese, setRecordsAdrese] = useState<DCRecord[]>([]);
  const [recordsDKP, setRecordsDKP] = useState<DCRecord[]>([]);
  const [recordsNC, setRecordsNC] = useState<DCRecord[]>([]);
  const [recordsRasvjetnaMjesta, setRecordsRasvjetnaMjesta] = useState<DCRecord[]>([]);
  const [expanded, setExpanded] = useState(false);

  // private layerSwitcherElementRef: React.RefObject<JSX.Element>;
  // private htrs96: Projection;
  // private wgs84: Projection;
  // private wgs84PM: Projection;
  // private defaultViewCenter: [number, number];
  // private initialDefaultExtent: [number, number, number, number];
  // private drawingSource: OlSourceVector<Geometry>;
  // private measurementsSource: OlSourceVector<Geometry>;

  // private fullScreenElementRef: React.RefObject<HTMLLIElement>;
  // private zoomToDefaultExtentElementRef: React.RefObject<HTMLLIElement>;

  const layerSwitcherElementRef = React.createRef<JSX.Element>();

  const dc = new dataController(model, "komunalni-obveznici/{lokacija_id}/objekti");

  const MAP_ID = 1;

  //define proj
  proj4.defs(
    "EPSG:3765",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:31276",
    "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection("EPSG:3765");
  const wgs84 = OlGetProjection("EPSG:4326");
  const wgs84PM = OlGetProjection("EPSG:3857");

  // defaultViewCenter = [1731757, 5581737];
  // initialDefaultExtent = [1688674,5501166,1688774,5501266];
  const defaultViewCenter = userContext?.mapSettings?.initial_view_center;
  const initialDefaultExtent = userContext?.mapSettings?.default_extent;

  // viewOptions = {
  //   center: defaultViewCenter,
  //   zoom: 8,
  //   //extent: defaultExtent,
  //   //projection: wgs84PM,
  //   minZoom: 8,
  //   maxZoom: 18
  // };



  useEffect(() => {
    getLayers();
  }, []);

  const getLayers = () => {
    mapService.getLayers(MAP_ID).then((coll) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  // getDefaultData() {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       setState((prevState) => {
  //         return {
  //           ...prevState,
  //           viewOptions: {
  //             ...prevState.viewOptions,
  //             zoom: viewData.initial_view_zoom,
  //             center: viewData.initial_view_center
  //           },
  //           zoomToExtent: viewData.default_extent,
  //           defaultExtent: viewData.default_extent
  //         };
  //       });
  //     }
  //   });
  // }

  const getFeatureType = (id: number) => {
    return "generic";
  };

  const setViewCenter = (view: { center: [number, number]; zoom: number }) => {
    setViewOptions((prevState) => {
      return {
        ...prevState,
        center: view.center,
        zoom: view.zoom
      };
    });
  };

  const changeCoordinateSystemDisplay = (type: CoordinateSystemType) => {
    setChosenCoordinateSystem(type);
  };

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    let hit = false;

    if (userContext && userContext.expireTime) {
      checkToken(userContext.expireTime)
    }

    if (drawType) {
      return;
    }

    // var feature = evt.map.forEachFeatureAtPixel(
    //   evt.pixel,
    //   function (feature) {
    //     return feature;
    //   },
    //   {
    //     layerFilter: (layer) => {
    //       const layerId = layer.get("id");
    //       return layerId !== null && layerId !== undefined && layerId.startsWith("objekti");
    //     }
    //   }
    // );

    // if (feature) {
    //   const id = feature.getId();
    //   hit = true;

    // } else {
    // }

    if (!hit) {
      const item = localStorage.getItem("maplayers");
      const visibility = item ? (JSON.parse(item) as BoolSettings) : ({} as BoolSettings);
      const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
      const allLayers = layersCollection
        ? flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup))
        : [];
      const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
      const GSLayerNames = visibleLayers.filter((x) => x.get("query") === true).map((x) => x.get("layer"));

      gsService.getFeatureInfo(evt.map, evt.pixel, GSLayerNames).then((resp) => {
        if (resp && Object.keys(resp).length != 0) {
          const layerKeys = Object.keys(resp);
          const objektiKey = layerKeys.find(x => x.substring(0, 7) === 'objekti');
          const stablaKey = layerKeys.find(x => x.substring(0, 6) === 'stabla');
          const dkpKey = layerKeys.find(x => x.substring(0, 3) === 'DKP');

          const objektiFeatures = objektiKey ? resp[objektiKey] : [];
          const stablaFeatures = stablaKey ? resp[stablaKey] : [];
          const dkpFeatures = dkpKey ? resp[dkpKey] : [];
        

          if (stablaKey && stablaFeatures.length > 0) {
            const stabaLayer = resp[stablaKey];
            const firstStabloFeature = stabaLayer[0];
            const featureType = "stabla"
            const data = {
              position: evt.coordinate as CardPositon,
              feature: firstStabloFeature,
              type: featureType as CardOverlayType,
              record: undefined
            } as ICardOverlayData;
            const showCard = () => showCardOverlay(featureType, data, null);
            closeOverlays(showCard);
          } else if (objektiKey && objektiFeatures.length > 0) {
            const objektiLayer = resp[objektiKey];
            const firstObjektiFeature = objektiLayer[0];
            const featureType = "objekti";
            const data = {
              position: evt.coordinate as CardPositon,
              feature: firstObjektiFeature,
              type: featureType as CardOverlayType,
              record: undefined
            } as ICardOverlayData
            const showCard = () => showCardOverlay(featureType, data, null);
            closeOverlays(showCard);
          }
          else if (dkpKey && dkpFeatures.length > 0) {
            const dkpLayer = resp[dkpKey];
            const firstDkpFeature = dkpLayer[0];
            const featureType = "dkp";
            const data = {
              position: evt.coordinate as CardPositon,
              feature: firstDkpFeature,
              type: featureType as CardOverlayType,
              record: undefined
            } as ICardOverlayData
            const showCard = () => showCardOverlay(featureType, data, null);
            closeOverlays(showCard);
          } 

          // addToTable(objektiFeatures);
          addToLayers(objektiFeatures);

        } else {
          closeOverlays(undefined);
        }
      });
    }
  };

  const addToTable = (features: GFIFeatureType[]) => {
    const records = features.map((x) => {
      if (x.properties.objekt_id) {
        return {
          id: x.properties.objekt_id,
          obj_id: (x.properties.objekt_id as number) % 1000000,
          fishnet_id: x.properties.fishnet_id,
          projekt_id: x.properties.projekt_id,
          naselje: x.properties.naselje,
          ulica: x.properties.ulica,
          kb: x.properties.kb,
          adresa_full: x.properties.adresa_full,
          kn_zona: x.properties.kn_zona,
          pre_pt: x.properties.pre_pt,
          kn_koef: x.properties.kn_koef,
          lokacija_id: x.properties.lokacija_id,
          ko: x.properties.ko,
          kc: x.properties.kc,
          broj_katova: x.properties.broj_katova
        } as DCRecord;
      } else {
        return {
          id: x.properties.id,
          oznaka: x.properties.oznaka
        } as DCRecord;
      }
    });

    setWorkingRecords((prevState) => {
      const currentIds = prevState.map((x) => x.id as number);
      const newRecords = records.filter((x) => currentIds.find((r) => r === x.id) === undefined) as DCRecord[];
      if (newRecords.length > 0) {
        return prevState.concat(newRecords);
      } else {
        return prevState;
      }
    });

    setSelectedRecords(records);
  };

  const addToLayers = (features: GFIFeatureType[]) => {
    const newOlFeatures = features.map((x) => {
      const newFeat = new OlFeature();
      const propId = x.properties.id;
      const id = typeof propId === "string" ? parseInt(propId, 10) : propId;
      newFeat.setId(id);
      Object.keys(x.properties).forEach((key) => {
        newFeat.set(key, x.properties[key]);
        newFeat.setGeometryName(x.geometry_name);
        if (x.geometry.type === "Polygon") {
          newFeat.setGeometry(new OlPolygon(x.geometry.coordinates as number[]));
        } else if (x.geometry.type === "Point") {
          newFeat.setGeometry(new OlPoint(x.geometry.coordinates as number[]));
        }
      });
      return newFeat;
    });

    setWorkingSource((prevState) => {
      const currentFeatures = prevState.getFeatures();
      if (currentFeatures.length > 0) {
        return new OlSourceVector({
          features: [
            // ...currentFeatures,
            ...newOlFeatures.filter((x) => prevState.getFeatureById(x.getId() as number) === null)
          ] as OlFeature<any>[]
        });
      } else {
        return new OlSourceVector({
          features: newOlFeatures
        });
      }
    });

    setSelectedSource(new OlSourceVector({ features: newOlFeatures }));
  };

  const handleSelectRecord = (id: number) => {
    const ft = workingSource.getFeatures().find((x) => x.getId() === id);
    if (ft) {
      setSelectedSource(
        new OlSourceVector({
          features: [ft]
        })
      );

      const extent = ft.getGeometry()?.getExtent();
      if (extent) {
        const [minx, miny, maxx, maxy] = extent;
        const center: [number, number] = [(minx + maxx) / 2, (miny + maxy) / 2];

        setViewOptions((prevState) => {
          return {
            ...prevState,
            center: center,
            animateDuration: 500
          };
        });
      }
    }
  };

  const handleRemoveRecord = (id: number) => {
    setWorkingRecords((prevState) => {
      return prevState.filter((x) => x.id !== id);
    });
    setWorkingSource((prevState) => {
      return new OlSourceVector({
        features: [...prevState.getFeatures().filter((x) => x.getId() !== id)]
      });
    });
    setSelectedSource((prevState) => {
      if (prevState.getFeatureById(id) !== null) {
        return new OlSourceVector({});
      } else {
        return prevState;
      }
    });
  };

  const handleRemoveAll = () => {
    setWorkingRecords([]);
    setWorkingSource(new OlSourceVector({}));
    setSelectedSource(new OlSourceVector({}));
  };

  const handleCustomAttributeTableAction = (action: string, id: number, record?: { [key: string]: any }) => {
    let locationId: number | null = null;
    if (record && 'lokacija_id' in record) {
      locationId = record['lokacija_id'] as number;
    }
    switch (action) {
      case "show_object":
        if (id) {
          const locId = locationId === null ? '' : `&loc_id=${locationId}`;
          const url = location.origin + "/komunalni-obveznici/?obj_id=" + id.toString() + "&open_first=true" + locId;
          window.open(url, "_blank");
        }
    }
  };

  const showCardOverlay = (type: CardOverlayType, data: ICardOverlayData, ft: OlFeature<any> | null) => {
    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }

    setCardOverlay({
      type: type,
      data: data
    });
    if (ft) {
      setFeature(ft);
    } else {
      setFeature(undefined);
    }
  };

  const closeOverlays = (callback: any) => {
    setCardOverlay(undefined);
    setExpanded(false)
    if (callback !== undefined && typeof callback === "function") {
      callback();
    }
  };

  const handleViewChangeCenter = (evt: MapEvent) => {
    if (evt && evt.map) {
      const newView = {
        center: evt.map.getView().getCenter(),
        zoom: evt.map.getView().getZoom()
      };
      setViewOptions(Object.assign(viewOptions, newView));
    }
  };

  const handleSidebarPaneChange = useCallback(
    (id) => {
      if (drawType && id && id != 'measures') { // id check so it only runs on opening sidebar panes (other than 'measures')
        setDrawType(undefined);
        setDrawInteractionType(undefined);
      }
    },
    [drawType]
  );

  const changeDrawType = (type: DrawingType) => {
    if (drawType !== type) {
      setDrawType(type);
      setDrawInteractionType(type === "Profile" ? "LineString" : type);
      setMeasuringFeature(undefined);
      if(type === "Profile"){
        handleEraseMeasurements()
      }
    } else {
      setDrawType(undefined);
      setDrawInteractionType(undefined);
      setMeasuringFeature(undefined);
    }
  };

  const handleDrawMeasureStart = (evt: DrawEvent) => {
    if (drawingSource.getFeatures().length > 0) {
      setDrawingSource(new OlSourceVector({}));
    }

    setMeasuringFeature(evt.feature);
  };

  const handleDrawMeasureEnd = (evt: DrawEvent) => {
    setDrawingSource(new OlSourceVector({}));
    const feat = evt.feature;
    feat.set("display_crs", chosenCoordinateSystem);
    if(drawType === "Profile"){
      handleEraseMeasurements()
      setMeasurementsSource(
        (prevState) =>
          new OlSourceVector({
            features: [evt.feature]
          })
      );
    }else{
      setMeasurementsSource(
        (prevState) =>
          new OlSourceVector({
            features: [...prevState.getFeatures(), evt.feature]
          })
      );
    }

    if (drawType ==="Point" || drawType === "Profile") {
      //@ts-ignore
      getProfilePoints(evt.target.getMap() as OlMap, feat);
    }

    setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
  };

  const handleDrawMeasureChange = (evt: BaseEvent) => {
    // console.log("handleDrawChange", evt);
  };

  const handleEraseMeasurements = () => {
    setMeasurementsSource(new OlSourceVector({}));
    setElevationPointsSource(new OlSourceVector({}));
  };

  const getProfilePoints = (map: any, feat: OlFeature<OlPoint | OlLineString>) => {
    const geom = feat.getGeometry();
    const feat_uuid = helpers.createUUID();
    if (geom) {
      if ( geom instanceof OlPoint) {
        const p = geom.getCoordinates();
        const pxPoint = map.getPixelFromCoordinate(p)
        gsService.getFeatureInfo(map, pxPoint, ["ANANDA:ananda_geoserver_dem"])
          .then((resp) => {
            const feat = parseAltResponse(resp, p);
            if (feat) {
              setElevationPointsSource(prevState => new OlSourceVector({
                features: prevState.getFeatures().concat([feat])
              }));
              setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
            }
          });
      } else if (geom instanceof OlLineString) {
        //coordinates in View projection
        const coords = geom.getCoordinates()
        //but we need pixel coordinates on a map
        const pxPoints = coords.map( x => map.getPixelFromCoordinate(x));
        //this can take some time so we turn on loader
        loaderContext.toggleLoading(true);
        //we call all GFI calls and continue when all responses are received (to calculcate dh we need to make sure that previous point is already received)
        Promise.all(pxPoints.map((px) => Promise.resolve(gsService.getFeatureInfo(map, px, ["ANANDA:ananda_geoserver_dem"]))))
        .then(responses => {
          //create features from responses
          const features = responses.map((resp, ii) => parseAltResponse(resp, coords[ii], feat_uuid, ii, ii > 0 ? getAlt(responses[ii-1]) : undefined));
          //filter null features if any
          const validFeatures = features.filter(x => x !== null) as OlFeature<OlPoint>[];
          //update source with all values at once
          setElevationPointsSource(prevState => new OlSourceVector({
            features: prevState.getFeatures().concat(validFeatures)
          }));
          //force refresh
          setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
        })
        .finally(() => {
          //when everything is ready, we turn off loader
          loaderContext.toggleLoading(false);
        })
      }
    }
  }

  const getAlt = (resp: GFIResponseType | null) => {
    const alt = resp ? resp[""][0].properties.GRAY_INDEX as number : undefined; // Ugly because response returns an object with key "" which containes an array
    if (typeof alt == 'number' && (alt < -413 || alt > 8848)) return undefined // Check if elevation data makes sense or its dem shenanigans
    return resp ? resp[""][0].properties.GRAY_INDEX as number : undefined;
  }

  const parseAltResponse = (resp: GFIResponseType | null, point: number[], feat_uuid?: string, ord?: number, prevAlt?: number) => {
    if (resp && Object.keys(resp).length != 0) {
      //@ts-ignore
      const alt: number = resp[""][0].properties.GRAY_INDEX; // Ugly because response returns an object with key "" which containes an array
      if (alt < -413 || alt > 8848) return null // Check if elevation data makes sense or its dem shenanigans
      const newFeat = new OlFeature({
        geometry: new OlPoint(point)
      })
      newFeat.set("alt", alt.toFixed(2));
      if (feat_uuid) {
        newFeat.set("line_uuid", feat_uuid);
      }
      if (ord !== undefined ) {
        newFeat.set("ord", ord);
        // console.log('got response for ord: ', ord);
      }

      if (prevAlt !== undefined) {
        newFeat.set("dh", (alt - prevAlt).toFixed(2))
      } else {
        newFeat.set("dh", 'N/A')
      }

      return newFeat;
    } else {
      return null;
    }
  }

  // function handleDownloadCSV() {
  //   const profileCsvContent = vectorSourceToCSV(elevationPointsSource, 'Profile');
  //   // const pointCsvContent = vectorSourceToCSV(elevationPointsSource, 'Point');

  //   if (profileCsvContent) { exportCSV(profileCsvContent, 'profiles.csv'); }
  //   // if (pointCsvContent) { exportCSV(pointCsvContent, 'points.csv'); }
  // }

  // function vectorSourceToCSV(vectorSource: OlSourceVector, geometryType: string) {
  //   if (!vectorSource) return null

  //   // Extract the features from the vector source
  //   const features = vectorSource.getFeatures().filter((feature) => {
  //     const geometry = feature.getGeometry();

  //     if (geometryType === 'Profile') {
  //       const properties = feature.getProperties()
  //       return 'line_uuid' in properties;
  //     } else if (geometryType === 'Point') {
  //       const properties = feature.getProperties()
  //       return !('line_uuid' in properties);
  //     } else {
  //       return geometry && geometry.getType() === geometryType;
  //     }
  //   });

  //   if (features.length == 0) return null

  //   const sortedFeatures = sortFeaturesByLineUUIDAndOrd(features);
    
  //   // Define a separator for the CSV values
  //   const separator = ',';
  
  //   // Extract the attribute names from the first feature, excluding the geometry attribute
  //   const attributeNames = Object.keys(sortedFeatures[0].getProperties()).filter((attributeName) => attributeName !== 'geometry');
  
  //   // Add 'X' and 'Y' to the attribute names for the coordinates
  //   const headerAttributeNames = ['X', 'Y', ...attributeNames];
  
  //   // Create the header row for the CSV file
  //   let csvContent = headerAttributeNames.join(separator) + '\n';
  
  //   // Iterate through the sorted features and extract their attributes
  //   sortedFeatures.forEach((feature: any) => {
  //     const properties = feature.getProperties();

  //     // Define an empty array to hold values for current row
  //     let rowValues = []
  
  //     // Get the geometry and extract X and Y coordinates
  //     const geometry = feature.getGeometry();
  //     if (geometry && geometry.getType() === 'Point') {
  //       const coordinates = geometry.getCoordinates();
  //       rowValues.push(coordinates[0], coordinates[1]);
  //     } else {
  //       rowValues.push('', '');
  //     }

  //     // Push the rest of attributes to the row
  //     rowValues.push(attributeNames.map((attributeName) => properties[attributeName]));
  
  //     // Add the row values to the CSV content
  //     csvContent += rowValues.join(separator) + '\n';
  //   });
  
  //   return csvContent;
  // }

  function sortFeaturesByLineUUIDAndOrd(features: OlFeature[]) {
    return features.sort((a, b) => {
      const aLineUUID = a.get('line_uuid');
      const bLineUUID = b.get('line_uuid');
      const aOrd = a.get('ord');
      const bOrd = b.get('ord');
  
      // Sort by 'line_uuid' first
      if (aLineUUID < bLineUUID) {
        return -1;
      } else if (aLineUUID > bLineUUID) {
        return 1;
      }
  
      // If 'line_uuid' is the same, sort by 'ord'
      return aOrd - bOrd;
    });
  }

  const handleLayerVisibilityChange = () => {
    // setForceRefreshCounter((prevState) => prevState + 1);
  };

  function checkToken(expireTime: Moment) {
    const isExpired = moment().isAfter(expireTime)
    if (isExpired) {
      authService.logout()
      snackbarContext.showNotification("messages.token_expired", "warning");
      navigate('/login')
    }
  }

  // const gsOverlay = cardOverlay && cardOverlay.type === "gsinfo" ? cardOverlay : undefined;
  const gsOverlay = cardOverlay ? cardOverlay : undefined;

  const fullTableHeight = 50 + 50 + 48 + 32 + workingRecords.length * 43;
  const constrainedTableHeight = fullTableHeight > 400 ? 400 : fullTableHeight;

  return (
    <Stack spacing={0} sx={{ display: "flex", height: "100%" }}>
      {userContext && layersCollection ? (
        <Box m={0} p={0} sx={{ flexGrow: 1 }}>
          <Map
            height={constrainedTableHeight}
            view={viewOptions}
            onClick={handleClick}
            onMoveend={handleViewChangeCenter}
            className="sidebar-map"
            id="main-map"
            zoomToExtentPadding={[50, 50, 50, 50]}
            initialized={mapInitialized}
            moveTolerance={10}
            maxTilesLoading={10}
          >
            <Controls>
              <CoordZoomStatusControl chosenCoordinateSystem={chosenCoordinateSystem} />
              <StatusControl changeCoordinateSystem={changeCoordinateSystemDisplay} />
              <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} />
              <ZoomSliderControl />
              {/*<ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} />*/}
              <ScaleLineControl />
              {/* <ScaleRatioControl viewOptions={viewOptions} ppi={96} /> */}
              <FullScreenControl tipLabel={t("map:controls.full_screen")} />
              <RotateControl autoHide={false} tipLabel={t("map:controls.rotate")} />
              {fullScreenElementRef && fullScreenElementRef.current && mapInitialized ? (
                <FullScreenControl
                  tipLabel={t("map:controls.full_screen")}
                  target={fullScreenElementRef.current}
                  className="ol-sidebar-control"
                />
              ) : null}

              {zoomToDefaultExtentElementRef?.current && mapInitialized ? (
                <ZoomToExtentControl
                  id="zoom-extent-default"
                  target={zoomToDefaultExtentElementRef.current}
                  extent={defaultExtent}
                  tipLabel={t("map:controls.zoom_to_extent")}
                  className="ol-sidebar-control"
                />
              ) : null}
              {zoomToSelectedExtentElementRef?.current && mapInitialized ? (
                <ZoomToExtentControl
                  id="zoom-extent-selected"
                  target={zoomToSelectedExtentElementRef.current}
                  extent={workingSource.getFeatures().length > 0 ? workingSource.getExtent() : defaultExtent}
                  tipLabel={t("map:controls.zoom_to_selected")}
                  className="ol-sidebar-control"
                  labelClass="fas fa-bullseye"
                />
              ) : null}
              {/* {geoLocateElementRef?.current && mapInitialized? (
              <GeoLocateControl
              id="geo-locate"
              tooltip={t("map:controls.geolocate")}
              target={geoLocateElementRef.current}
              className="ol-sidebar-control"
              />
              ) : null} */}
              {centerMarkerElementRef?.current && mapInitialized ? (
                <CenterMarkerControl
                  id="center-marker"
                  tooltip={t("map:controls.center_marker")}
                  target={centerMarkerElementRef.current}
                  className="ol-sidebar-control"
                />
              ) : null}
              {printElementRef?.current && mapInitialized ? (
               <PrintControl 
                tooltip={t("map:controls.full_screen")} 
                dpi={300} 
                target={printElementRef.current}
                className="ol-sidebar-control"
                //onPrint={(e:any) => {console.log("print", e)}}
                //onClick={(e:any) => {console.log("print", e)}}
              />
              ) : null }
              <ViewHistoryControl />
              <GeoBaseLayerSwitcher allowNoLayer={true} mapId={MAP_ID} />
              <SidebarControl onTabChange={handleSidebarPaneChange}>
                <SidebarTabs>
                  <SidebarTabList>
                    <SidebarTabListItem
                      id="info"
                      title={t("map:sidebar.info")}
                      icon={<i className="fas fa-info-circle"></i>}
                    />
                    <SidebarTabListItem
                      id="layers"
                      title={t("map:sidebar.layers")}
                      icon={<i className="fas fa-layer-group"></i>}
                    />
                    <SidebarTabListItem
                      id="legend"
                      title={t("map:sidebar.legend")}
                      icon={<i className="fas fa-list-alt"></i>}
                    />
                    <SidebarTabListItem
                      id="measures"
                      title={t("map:sidebar.measures")}
                      icon={<i className="fas fa-pencil-ruler"></i>}
                    />
                    <li ref={zoomToDefaultExtentElementRef}></li>
                    <li ref={zoomToSelectedExtentElementRef}></li>
                    <li ref={fullScreenElementRef}></li>
                    {/* <li ref={geoLocateElementRef}></li> */}
                    <li ref={centerMarkerElementRef}></li>
                    <li ref={printElementRef}></li>
                  </SidebarTabList>
                </SidebarTabs>
                <SidebarContent>
                  <SidebarPane id="info">
                    <SidebarHeading title={t("map:sidebar.info")} />
                    <InfoPane />
                  </SidebarPane>
                  <SidebarPane id="layers">
                    <SidebarHeading title={t("map:sidebar.layers")} />
                    <LayerTree
                      ready={layersCollection ? true : false}
                      layersCollection={layersCollection}
                      onLayerVisibilityChange={handleLayerVisibilityChange}
                    />
                    {/* <LayersPane layersGroup={true} /> */}
                  </SidebarPane>
                  <SidebarPane id="legend">
                    <SidebarHeading title={t("map:sidebar.legend")} />
                    <LegendPane
                      // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                      layersCollection={layersCollection}
                    />
                  </SidebarPane>
                  <SidebarPane id="measures">
                    <SidebarHeading title={t("map:sidebar.measures")} />
                    <MeasuresPane
                      // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                      changeDrawType={changeDrawType}
                      handleEraseMeasurements={handleEraseMeasurements}
                      drawType={drawType}
                      //handleDownloadCSV={handleDownloadCSV}
                      elevationPointsFeatures={elevationPointsFeatures}
                      measurementsSource={measurementsSource}
                    />
                  </SidebarPane>
                </SidebarContent>
              </SidebarControl>
              {/* <AttributeTableControl
                dc={dc}
                records={workingRecords}
                selectedRecords={selectedRecords}
                onSelectRecord={handleSelectRecord}
                onRemoveRecord={handleRemoveRecord}
                onCustomAction={handleCustomAttributeTableAction}
                onClose={handleRemoveAll}
                autohide={true}
              /> */}
            </Controls>
            {layersCollection ? (
              <Layers>
                <GeoAPILayers layersCollection={layersCollection} />
                <VectorLayer id="selected" source={workingSource} style={selectedRecordStyle} zIndex={950} />
                <VectorLayer id="highlighted" source={selectedSource} style={hoveringRecordStyle} zIndex={960} />
                <VectorLayer
                  id="measurements"
                  source={measurementsSource}
                  style={measurementsStyle}
                  zIndex={900}
                // title="Temp measurements"
                />
                <VectorLayer id="elevation_points" source={elevationPointsSource} style={elevationStyle} zIndex={901} />
              </Layers>
            ) : null}
            
            <Overlays>
              <PopupOverlay
                id="feature-overlay"
                position={gsOverlay ? gsOverlay.data.position : undefined}
                autoPan={true}
                onClose={() => { }}
              >
                {gsOverlay ? <GSInfoCard featureData={gsOverlay.data} onClose={closeOverlays} expanded={expanded} setExpanded={setExpanded} /> : null}
              </PopupOverlay>
              <MeasureTooltipOverlay id="measure-overlay" feature={measuringFeature} position={undefined} />
            </Overlays>
            <Interactions>
              <DefaultInteractions />
              {drawInteractionType ? (
                <DrawInteraction
                  source={drawingSource}
                  type={drawInteractionType}
                  style={measuringStyle}
                  onChange={handleDrawMeasureChange}
                  onDrawstart={handleDrawMeasureStart}
                  onDrawend={handleDrawMeasureEnd}
                />
              ) : null}
            </Interactions>
          </Map>
        </Box>
      ) : null}
      {workingRecords.length > 0 ? (
        <Box m={0} p={0}>
          <AttributeTable
            dcDictionary={{
              'objekti': dc
            }}
            records={workingRecords}
            selectedRecords={selectedRecords}
            onSelectRecord={handleSelectRecord}
            onRemoveRecord={handleRemoveRecord}
            onCustomAction={handleCustomAttributeTableAction}
            onClose={handleRemoveAll}
            autohide={true}
          />
        </Box>
      ) : null}
    </Stack>
  );
}

export default MainMap;
