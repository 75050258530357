import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material"

const ProfileChart: React.FunctionComponent<any> = (props: any) => {
  
  const { series, handleChartMouseEnter, handleChartMouseLeave, title, handleChartDataMounted } = props;
  const [ modifiedSeries, setModifiedSeries ]: any = useState()
  
  const theme = useTheme()
  const { t } = useTranslation();

  const seriesStart = series ? series.data[0][0] : 0
  const seriesEnd = series ? series.data[series.data.length - 1][0] : 0
  const seriesDifference = seriesEnd - seriesStart
  
  useEffect(() => {
    if(series){
      let newModifiedSeries=[series]
      newModifiedSeries['0'].name = t("map:route.graphs.altitude_abbr")
      setModifiedSeries(newModifiedSeries)
    }

  },[series])
  
  function handleZoomLimit(e: any, {xaxis}: any) {
    const zoomDifference = xaxis.max - xaxis.min
    if (zoomDifference > seriesDifference) {
      return {
        // Dont zoom any more
        xaxis: {
          min: seriesStart,
          max: seriesEnd
        }
      }
    }
    else {
      return {
        // Keep on zooming
        xaxis: {
          min: xaxis.min,
          max: xaxis.max
        }
      }
    }
  }

  const options={
    title: {
      text: title,
      align: 'center',
      style: {
        color: theme.palette.text.primary
      }
    },
    chart: {
      zoom: {
        enabled: true
      },
      height: 300,
      type: "area",
      stacked: false,
      events: {
        mouseMove: handleChartMouseEnter,
        mouseLeave: handleChartMouseLeave,
        beforeZoom: handleZoomLimit,
        mounted: handleChartDataMounted,
        //animationEnd: handleChartDataMounted
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#ff1f1f", "#0000FF", "#00FF00"],
    fill: {
      colors: ['#666666']
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 2
    },
    yaxis: {
      title: {
        text: t("map:route.graphs.altitude"),
        style: {
          color: theme.palette.text.primary
        }
      },
      labels: {
        style: {
          colors: theme.palette.text.primary
        },
        formatter: (val: number) => {
          return `${val.toFixed(2)} m`
        }
      }
    },
    xaxis: {
      type: "numeric",
      min: 0,
      max: seriesEnd,
      tickAmount: 4,
      title: {
        text: t("map:route.graphs.distance"),
        style: {
          color: theme.palette.text.primary
        },
      },
      labels: {
        style: {
          colors: theme.palette.text.primary
        },
        formatter: (val: number) => {
          if (seriesEnd > 1000) {
            return `${(val / 1000).toFixed(1)} km`
          } else {
            return `${Math.round(val)} m`
          }
        }
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40
    }
  }

  return (
    modifiedSeries ? 
   
      <Chart
        type="area"
        // @ts-ignore
        options={options}
        series={modifiedSeries}
        height="300px"
      /> 
    : null
  );
}


export default ProfileChart;